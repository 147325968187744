import Vue from 'vue';
import App from './App.vue';
import store from './store';
import VueRouter from 'vue-router';
import router from './router';
import 'normalize.css';
import './index.css';

import ElementUI from 'element-ui';
import './elementStyle/index.css';// element-ui 自定义主题

// echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts
// ---

// 引入省市区
import { regionData } from 'element-china-area-data'
for (let i = 0; i < regionData.length; i++) {
  const province = regionData[i].children;
  for (let j = 0; j < province.length; j++) {
    if (province[j].children) {
      const city = province[j].children;
      if (city.length && city[0].label == '市辖区') {
        city.splice(0, 1)
      }
    }
  }
}
let guangdong_Index = regionData.findIndex(item => item.label == '广东省')
let guangdong = regionData.splice(guangdong_Index, 1)[0]
regionData.unshift(guangdong)
Vue.prototype.$regionData = regionData
// ---

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(ElementUI)
Vue.config.productionTip = false

/** 货物属性 */
let goodsProperty = {
  'P01': '带电',
  'P02': '带磁',
  'P03': '带电带磁',
  'P04': '纺织品&皮具',
  'P05': '鞋子',
  'P06': '玩具',
  'P07': '电池',
  'P08': '液体',
  // 'P09': '食品',
  'P10': '普货',
}
Vue.filter('goodsProperty', function (val) {
  return goodsProperty[val]
})
/** 运输方式 */
let transportType = {
  'P01': '海派专线',
  'P02': '空派专线',
  'P03': '海卡专线',
  'P04': '快递专线',
  // 'P05': '整柜专线',
  'P06': '铁派专线',
  'P07': '其他专线',
  'P08': '卡航专线',
}
Vue.filter('transportType', function (val) {
  return transportType[val]
})
/** 货币类型 */
let unitType = {
  '￥': 'CNY',
  '$': 'USD',
  '￡': 'GBP',
  '€': 'EUR',
  'J￥': 'JPY',
}
Vue.filter('unitType', function (val) {
  return unitType[val]
})
/** 订单状态 */
let orderStatus = {
  'S01': '下单成功',
  'S02': '已收货',
  'S03': '转运中',
  'S04': '已签收',
  'S05': '订单异常',
  'S06': '已取消',
}
Vue.filter('orderStatus', function (val) {
  return orderStatus[val]
})
Vue.filter('strSub', function (str, num) {
  return str.substring(0, num)
})
// 将所有filter对象的key整理为数组挂载到全局
Vue.prototype.global = {
  list_goodsProperty: Object.keys(goodsProperty),
  list_transportType: [
    'P01',
    'P02',
    'P03',
    'P04',
    // 'P05': '整柜专线',
    'P06',
    'P08',
    'P07',
  ],
  list_unitType: Object.keys(unitType),
  list_orderStatus: Object.keys(orderStatus),
  uploadApi: process.env.VUE_APP_api + '/store/upload'
}

// 路由白名单
let white_list = ['/index/index', '/login']
// 路由跳转拦截
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (white_list.includes(to.path)) {
    next()
  } else {
    if (localStorage.getItem('id')) {
      next()
    } else {
      router.push('/login')
    }
  }
})

new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app')
