import VueRouter from "vue-router";

import userCenter_account from "./userCenter/account";
import userCenter_order from "./userCenter/order";
import userCenter_config from "./userCenter/config";
import userCenter_message from "./userCenter/message";
import userCenter_main from "./userCenter/main";

export default new VueRouter({
    routes: [
        { path: '/', redirect: '/index' },
        { path: '/login', component: () => import('@/views/login.vue'), meta: { title: '登录' } },
        {
            path: '/index',
            redirect: '/index/index',
            component: () => import('@/views/page.vue'),
            children: [
                { path: 'index', component: () => import('@/views/index/index.vue'), meta: { title: '首页' } },
                { path: 'search', component: () => import('@/views/index/search.vue'), name: '运价查询', meta: { title: '运价查询' } },
                { path: 'server', component: () => import('@/views/index/server.vue'), meta: { title: '服务详情' } },
                { path: 'createOrder', component: () => import('@/views/index/createOrder.vue'), meta: { title: '立即下单' } },
                { path: 'orderSuccessful', component: () => import('@/views/index/orderSuccessful.vue'), meta: { title: '下单成功' } }
            ]

        },
        {
            path: '/userCenter',
            redirect: '/userCenter/main/index',
            component: () => import('@/views/page.vue'),
            children: [
                userCenter_account,
                userCenter_order,
                userCenter_config,
                userCenter_message,
                userCenter_main
            ]

        },
        {
            path: '/gift_pack',
            component: () => import('@/views/page.vue'),
            redirect: '/gift_pack/index',
            children: [
                { path: 'index', component: () => import('@/views/gift_pack/index.vue'), meta: { title: '创业礼包' } }
            ]
        }

    ]
})

